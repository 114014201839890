import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import 'firebase/messaging';

var firebaseConfig = {
    apiKey: "AIzaSyBnjIYwjTXlvzg7Ocwf4y5KN7NRhTZYLHw",
    authDomain: "pwa-test-b4f42.firebaseapp.com",
    projectId: "pwa-test-b4f42",
    storageBucket: "pwa-test-b4f42.appspot.com",
    messagingSenderId: "522143645527",
    appId: "1:522143645527:web:38c76cfd83c46abdc24812"
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

export const getToken = (setTokenFound) => {
  console.log(firebase.messaging.isSupported())
    
  if(firebase.messaging.isSupported()){
    return messaging.getToken({vapidKey: 'BAGxdEBP2PIjBexpKd0Xg3M2Z-4S5pDM6VJO9i84CqrNp3QlllhPWH12DD53akWoi5Hm5wjX9NFQvAlvxqO5ZFs'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});